import request from "@/utils/request";

export function getPlayList() {
  return request(
    {
      url: 'video/play-lists',
      method:"get"
    }
  )
}
