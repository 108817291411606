<template>
  <div class="container-fluid mt-3">
    <div class="d-flex justify-content-between flex-wrap">
    <Breadcrumb current_page="قوائم التشغيل"/>

    <collapse-filter>
      <form @submit.prevent="handleSearch" class="d-flex gap-3 flex-wrap filter-form">
        <div class="form-group">
          <label>بحث بحسب ال id</label>
          <input type="text" v-model="id" class="form-control"/>
        </div>

        <div class="form-group">
          <label>العنوان</label>
          <input type="text" v-model="title" class="form-control"/>
        </div>

        <div class="form-group">
          <label class="d-block">التصنيف</label>
          <el-select v-model="category_id" filterable placeholder="Select">
            <el-option
                v-for="item in categories"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </div>

        <div class="form-group">
          <label>التاريخ</label>
          <v-date-picker v-model="dateRange" is-range>
            <template v-slot="{ inputValue, inputEvents }">
              <div class="flex justify-center items-center">
                <input
                    :value="inputValue.start ? `${inputValue.start} - ${inputValue.end}` : ''"
                    v-on="inputEvents.start"
                    class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                />
              </div>
            </template>
          </v-date-picker>
        </div>

        <div class="form-group align-self-end ">
          <button class="btn btn-search px-3" type="submit" @click="handleSearch" :disabled="loading">
            بحث
          </button>
        </div>
      </form>
    </collapse-filter>
    </div>

    <el-card v-if="!loading" class="mt-2">
      <table  v-if="playLists.length" class="table text-center custom-border dashboard-table table-row-dashed h-100">
        <thead>
        <tr>
          <th>الترتيب</th>
          <th>صورة الغلاف</th>
          <th>الاسم</th>
          <th>الناشر</th>
          <th>عدد الفيديوهات</th>
          <th>التاريخ</th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="item in playLists" :key="item.id">
        <td>
          {{item.id}}
        </td>
          <td>
            <img src="{{APP_URL}}/70x70/{{item.cover_photo}}" alt="">
          </td>
          <td>{{ item.publisher.display_name }}</td>
          <td>{{ item.no_of_videos }}</td>
          <td>{{ item.created_at }}</td>
        </tr>
        </tbody>
      </table>

      <p v-else>
 لا يوجد بيانات
      </p>


    </el-card>

    <Loader v-else />
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import {getNewsCategories} from "@/services/news";
import {getPlayList} from "@/services/playList";
import Loader from "@/components/Loader";
import CollapseFilter from "../../components/CollapseFilter";

export default  {
  name: 'PlayList',
  components: {
    Loader,
    Breadcrumb,
    CollapseFilter
  },
  data() {
    return {
      loading      : false,
      playLists : [],
      categories   : [],
      id           : '',
      title        : '',
      category_id  : '',
      dateRange    : {
        start: '',
        end  : ''
      },
      page: 1,
      total: 1,
      per_page: 1,
      paginationOptions: {
        chunk: 5,
        theme: "bootstrap4",
        //hideCount: true
      }
    }
  },
  mounted() {
    this.fetchPlayLists();
    this.fetchCategories();
  },
  computed: {
    APP_URL: function () {
      return process.env.VUE_APP_IMAGE_REMOTE_URL
    },
  },
  methods: {
    fetchCategories()
    {
      getNewsCategories()
          .then((res) => {
            this.categories = res.data;
          })
          .catch((e) => {
            console.log(e);
          });
    },
    fetchPlayLists(){
      this.loading = true
      getPlayList()
      .then(res=> {
        this.loading = false
        this.playLists = res.data
      })
      .catch(()=>{
        this.loading = false
      })
    },
    handleSearch(){
      console.log('tsst')
    }
  }
}
</script>
